<template>
  <div>
    <!-- tags -->
    <h4 class="font-weight-bolder text-dark mb-4">Tags</h4>
    <div v-if="tags.length">
      <v-chip
        class="mr-2 mb-2"
        label
        v-for="(tag, index) in tags"
        :key="index"
        color="primary"
        outlined
      >
        {{ tag.tag }}
      </v-chip>
    </div>
    <h6 class="text-muted mb-0" v-else-if="!loading && tags.length === 0">
      No Data
    </h6>
    <!-- end tags -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Tags",
  computed: {
    ...mapGetters({
      loading: "getLoadingStatus",
      tags: "talentProfile/getTalentTags",
    }),
  },
  created() {
    this.$store.dispatch("talentProfile/getTalentTags", this.$route.params.id);
  },
};
</script>
