<template>
  <v-app id="talents-list" class="talents-list">
    <div class="card card-custom card-stretch h-auto">
      <!--begin::Body-->
      <div class="card-body">
        <Tags />
        <!-- <TimeLine /> -->
      </div>
      <!--end::Body-->
    </div>
  </v-app>
</template>

<script>
import Tags from "@/components/talent-timeline/Tags.vue";
// import TimeLine from "@/components/talent-timeline/TimeLine.vue";
export default {
  name: "TalentQualifications",
  components: {
    Tags,
    // TimeLine,
  },
};
</script>
